import React from 'react';
import PropTypes from 'prop-types';
import QuestionTag from '../QuestionTag/QuestionTag';
import qListStyle from './QuestionList.module.css';
import { partition } from '../../../lib/utilities';

const QuestionList = ({ questions }) => {
  const [questionsH, questionsV] = partition(questions, (x) => x.direction);
  return (
    <div className={qListStyle.allQuestions}>
      <div>
        <span className={qListStyle.qListTitle}>Waagrecht (J + Y = I):</span>
        <ul className={qListStyle.qList}>
          {questionsH.map((q) => (
            <li key={`qh-${q.qNr}`}>
              <span className={qListStyle.qNumber}>{q.qNr}.</span>
              <QuestionTag qNo={q.qNr} direction question={q.question} />
            </li>
          ))}
        </ul>
      </div>
      <div>
        <span className={qListStyle.qListTitle}>Senkrecht (J + Y = I):</span>
        <ul className={qListStyle.qList}>
          {questionsV.map((q) => (
            <li key={`qv-${q.qNr}`}>
              <span className={qListStyle.qNumber}>{q.qNr}.</span>
              <QuestionTag qNo={q.qNr} direction={false} question={q.question} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
export default QuestionList;

QuestionList.propTypes = {
  questions: PropTypes.array.isRequired,
};
