import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import icons from '../../../img/GameIcons.svg';
import iconBtnStyles from './IconButton.module.css';

const IconButton = ({
  buttonText,
  iconName,
  buttonStyleClass,
  disabled,
  showText,
  onClick,
}) => (
  <button
    type="button"
    title={buttonText}
    aria-label={buttonText}
    className={cn(buttonStyleClass, iconBtnStyles.iconButton)}
    onClick={onClick}
    disabled={disabled}
  > {showText && `${buttonText}`}
    <svg>
      <use xlinkHref={`${icons}#${iconName}`} />
    </svg>
  </button>
);

export default IconButton;

IconButton.defaultProps = {
  buttonText: undefined,
  buttonStyleClass: undefined,
  onClick: undefined,
  disabled: false,
  showText: false,
};

IconButton.propTypes = {
  iconName: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  buttonStyleClass: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  showText: PropTypes.bool,
};
