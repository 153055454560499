import React from 'react';
import PropTypes from 'prop-types';

import StatsBar from './StatsBar/StatsBar';
import QuestionStore from '../../models/QuestionStore';
import statsStyle from './Stats.module.css';

export default class Stats extends React.Component {
  constructor() {
    super();
    this.state = {
      questionHistory: [],
    };
  }

  componentDidMount() {
    const { currentCrosswordId } = this.props;
    QuestionStore.getQuestionHistory(currentCrosswordId)
      .then((qHistory) => {
        this.setState({
          questionHistory: qHistory || [],
        });
      });
  }

  getLetterList() {
    const { gridValues } = this.props;
    const letterList = Array(27);
    let totalCount = 0;
    for (let i = 65; i <= 90; i++) {
      letterList[String.fromCharCode(i)] = 0;
    }
    letterList[''] = 0;

    for (let y = 0; y < gridValues.length; y++) {
      for (let x = 0; x < gridValues[y].length; x++) {
        const c = gridValues[y][x];
        letterList[c] += 1;
        totalCount++;
      }
    }

    return [letterList, totalCount];
  }

  getQuestionStats() {
    const { questions } = this.props;
    const { questionHistory } = this.state;
    const nrLettersByWords = Array(questions.length);
    const previousWords = [];

    questions.forEach((q) => {
      if (!q.solution) {
        return;
      }
      if (nrLettersByWords[q.length]) {
        nrLettersByWords[q.length] += 1;
      } else {
        nrLettersByWords[q.length] = 1;
      }
      const prevEntries = questionHistory.filter((qh) => qh.solution === q.solution);
      if (prevEntries.length > 0) {
        previousWords.push(...prevEntries);
      }
    });

    return [nrLettersByWords, previousWords, questions.length];
  }

  render() {
    const { onShowDoubledClick } = this.props;
    const [letterList, totalCount] = this.getLetterList();
    const [lettersByWordList, previousWords, totalQuestions] = this.getQuestionStats();
    const listBar = [];
    const lettersByWordBar = [];
    const prevWordList = [];
    // eslint-disable-next-line vars-on-top, no-var, guard-for-in, no-restricted-syntax
    for (var key in letterList) {
      listBar.push(
        <li key={key}>
          <span className={statsStyle.desc}>{(key.length <= 0 ? '[]' : key)}: </span><StatsBar amount={letterList[key]} totalSize={totalCount} />
        </li>,
      );
    }

    // eslint-disable-next-line vars-on-top, no-var, guard-for-in, no-restricted-syntax
    for (var l in lettersByWordList) {
      lettersByWordBar.push(
        <li key={l}>
          <span className={statsStyle.descLong}>{l} Bst: </span><StatsBar amount={lettersByWordList[l]} totalSize={totalQuestions} />
        </li>,
      );
    }

    // eslint-disable-next-line vars-on-top, no-var, guard-for-in, no-restricted-syntax
    for (var p in previousWords) {
      const pWord = previousWords[p];
      prevWordList.push(
        <li key={p}>{pWord.solution} in {pWord.crossword}</li>,
      );
    }

    return (
      <div className={statsStyle.stats}>
        <div className={statsStyle.title}>--- Statistiken -----------</div>
        <button type="button" onClick={onShowDoubledClick}>Doppelt belegte Buchstaben anzeigen</button>
        <div className={statsStyle.statsLists}>
          <ul><li>Buchstabenhäufigkeit</li>{listBar}</ul>
          <ul><li>Anzahl Wörter nach Wortlänge</li>{lettersByWordBar}</ul>
          <ul><li>Wörter in früheren Rätseln</li>{prevWordList}</ul>
        </div>

      </div>
    );
  }
}

Stats.defaultProps = {
};

Stats.propTypes = {
  currentCrosswordId: PropTypes.number.isRequired,
  gridValues: PropTypes.array.isRequired,
  questions: PropTypes.array.isRequired,
  onShowDoubledClick: PropTypes.func.isRequired,
};
