import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import listItemStyle from './ListItem.module.css';

export default function ListItem({ game, enableEdit, onDeleteClick }) {
  const listItem = enableEdit ? (
    <li key={game.id} className={cn(listItemStyle.gameListItem, listItemStyle.editable)}>
      <span className={listItemStyle.gameNr}>Nr {game.id}:</span> <span>{game.name}</span>
      <span><Link to={`/aag/edit/${game.id}/`}>editieren</Link></span>
      {game.date_published && <span><Link to={`/aag/solve/${game.id}/`}>lösen</Link></span>}
      <button type="button" className="linkButton" onClick={() => onDeleteClick(game.id)}>löschen</button>
    </li>
  )
    : (
      <li key={game.id} className={cn(listItemStyle.gameListItem, listItemStyle.published)}>
        <Link to={`/aag/solve/${game.id}/`}>
          <span className={listItemStyle.gameNr}>Nr {game.id}:</span> <span>{game.name}</span>
        </Link>
      </li>
    );
  return listItem;
}

ListItem.defaultProps = {
  enableEdit: false,
  onDeleteClick: () => {},
};

ListItem.propTypes = {
  game: PropTypes.object.isRequired,
  enableEdit: PropTypes.bool,
  onDeleteClick: PropTypes.func,
};
