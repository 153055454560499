import React from 'react';
import Keyboard from 'react-simple-keyboard';
import PropTypes from 'prop-types';
import 'react-simple-keyboard/build/css/index.css';

import keyboardStyle from './SimpleKeyboard.module.css';

export default class SimpleKeyboard extends React.Component {
  handleChange(input) {
    const { onChange } = this.props;
    onChange(input);
    this.keyboard.clearInput();
  }

  render() {
    return (
      <Keyboard
        keyboardRef={(r) => { this.keyboard = r; }}
        onChange={(input) => this.handleChange(input)}
        useButtonTag
        theme={`hg-theme-default hg-layout-default ${keyboardStyle.keyboardInput}`}
        // layoutName="default"
        display={{
          '{bksp}': '⌫',
        }}
        layout={{
          default: [
            'Q W E R T Y U I O P',
            'A S D F G H J K L',
            'Z X C V B N M {bksp}',
          ],
          shift: [
            'Q W E R T Y U I O P',
            'A S D F G H J K L',
            '<< Z X C V B N M >>',
          ],
        }}
        buttonTheme={[
          {
            class: 'keyboard-input',
            buttons: 'Q W E R T Y U I O P A S D F G H J K L Z X C V B N M {bksp}',
          },
        ]}
      />
    );
  }
}

SimpleKeyboard.propTypes = {
  onChange: PropTypes.func.isRequired,
};
