import React from 'react';
import PropTypes from 'prop-types';
import SimpleKeyboard from '../SimpleKeyboard/SimpleKeyboard';
import SolutionCheckButton from '../../game/SolutionCheckButton/SolutionCheckButton';


import fixedBottomStyle from './FixedBottom.module.css';

const FixedBottom = ({
  onKeyboardInput,
  game,
  gridValues,
  onSolutionError,
  onSolutionChecked,
}) => (
  <div className={fixedBottomStyle.fixedBottom}>
    <SolutionCheckButton
      showText
      sticky
      game={game}
      gridValues={gridValues}
      onSolutionError={() => onSolutionError}
      onSolutionChecked={(n, f) => onSolutionChecked(n, f)}
    />
    <SimpleKeyboard onChange={(v) => onKeyboardInput(v)} />
  </div>
);

export default FixedBottom;

FixedBottom.propTypes = {
  game: PropTypes.object.isRequired,
  gridValues: PropTypes.array.isRequired,
  onKeyboardInput: PropTypes.func.isRequired,
  onSolutionError: PropTypes.func.isRequired,
  onSolutionChecked: PropTypes.func.isRequired,
};
