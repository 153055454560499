import React from 'react';
import PropTypes from 'prop-types';

import cellStyle from './GameCell.module.css';

export default class GameCell extends React.Component {
  constructor() {
    super();
    this.clickCount = 0;
  }

  handleClick(ev) {
    const {
      onClick,
      qH,
      qV,
      x,
      y,
    } = this.props;
    onClick(this.clickCount > 0, x, y, qH, qV, ev.shiftKey);

    this.clickCount++;
  }

  handleMouseLeave() {
    const { onMouseLeave } = this.props;
    this.clickCount = 0;
    onMouseLeave();
  }

  render() {
    const {
      cellSize,
      disabled,
      borderLeft,
      borderRight,
      borderTop,
      borderBottom,
      mouseOveredH,
      mouseOveredV,
      belongsToSolutionWord,
      qNr,
      inputRef,
      qH,
      qV,
      x,
      y,
      cellValue,
      isEditable,
      isActiveCell,
      readOnly,
      onChange,
      onMouseEnter,
      onCellKeydown,
      showHorizontalQ,
    } = this.props;

    const currentStyle = { width: `${cellSize}px`, height: `${cellSize}px` };

    if (!isEditable && disabled) {
      return <span className={cellStyle.disabled} style={currentStyle} />;
    }

    const classes = [cellStyle.cell];
    if (disabled) {
      classes.push(cellStyle.disabled);
    }
    if (borderLeft) {
      classes.push(cellStyle.borderLeft);
    }
    if (borderRight) {
      classes.push(cellStyle.borderRight);
    }
    if (borderTop) {
      classes.push(cellStyle.borderTop);
    }
    if (borderBottom) {
      classes.push(cellStyle.borderBottom);
    }
    if (belongsToSolutionWord) {
      classes.push(cellStyle.solWord);
    }

    if (mouseOveredH) {
      classes.push(cellStyle.borderedH);
      if (showHorizontalQ) {
        classes.push(cellStyle.highlighted);
      }
    } else if (mouseOveredV) {
      classes.push(cellStyle.borderedV);
      if (!showHorizontalQ) {
        classes.push(cellStyle.highlighted);
      }
    }

    if (isActiveCell) {
      classes.push(cellStyle.active);
    }

    return (
      <div className={classes.join(' ')} style={currentStyle}>
        {qNr && <span className={cellStyle.qNr}>{qNr}</span>}
        <input
          readOnly={readOnly}
          className="cell"
          ref={inputRef}
          value={cellValue || ''}
          type="text"
          maxLength="1"
          tabIndex="-1"
          data-qh={qH}
          data-qv={qV}
          onClick={(ev) => this.handleClick(ev)}
          onChange={(i) => onChange(x, y, i.target.value)}
          onFocus={(e) => { setTimeout(e.target.select(), 50); }}
          onMouseEnter={() => onMouseEnter(x, y)}
          onMouseLeave={() => this.handleMouseLeave()}
          onKeyDown={(ev) => onCellKeydown(ev.shiftKey, ev.keyCode, ev.target.value)}
        />
      </div>
    );
  }
}

GameCell.defaultProps = {
  inputRef: () => {},
  qNr: undefined,
  qV: undefined,
  qH: undefined,
  cellValue: '',
  borderLeft: false,
  borderRight: false,
  borderTop: false,
  borderBottom: false,
  mouseOveredH: false,
  mouseOveredV: false,
  disabled: false,
  readOnly: false,
  belongsToSolutionWord: false,
  showHorizontalQ: true,
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  onClick: () => {},
  onCellKeydown: () => {},
};

GameCell.propTypes = {
  inputRef: PropTypes.func,
  qNr: PropTypes.number,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  showHorizontalQ: PropTypes.bool,
  cellValue: PropTypes.string,
  cellSize: PropTypes.number.isRequired,
  qH: PropTypes.number,
  qV: PropTypes.number,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onClick: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onCellKeydown: PropTypes.func,
  borderLeft: PropTypes.bool,
  borderRight: PropTypes.bool,
  borderTop: PropTypes.bool,
  borderBottom: PropTypes.bool,
  mouseOveredH: PropTypes.bool,
  mouseOveredV: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  belongsToSolutionWord: PropTypes.bool,
  isEditable: PropTypes.bool.isRequired,
  isActiveCell: PropTypes.bool.isRequired,
};
