/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import solwordStyles from './EditSolutionWord.module.css';

export default function EditSolutionWord({ game, onSaveGame }) {
  const [solutionQuestion, setSolutionQuestion] = useState(game.solution_question);
  const saveGameProperty = (name, value) => {
    const changedGame = game;
    changedGame[name] = value;
    onSaveGame(changedGame);
  };

  return (
    <div className={solwordStyles.editSolWord}>
      <span className={solwordStyles.title}>--- Lösungwort -----------</span>
      <div>
        <div>
          <input
            type="radio"
            id="cwVertical"
            name="solutionWordDirection"
            onChange={() => saveGameProperty('solution_word_direction', true)}
            value
            checked={game.solution_word_direction === true}
          />
          <label htmlFor="cwVertical">Lösungswort Waagrecht</label>
        </div>
        <div>
          <input
            type="radio"
            id="cwHorizontal"
            name="solutionWordDirection"
            onChange={() => saveGameProperty('solution_word_direction', false)}
            value={false}
            checked={game.solution_word_direction === false}
          />
          <label htmlFor="cwHorizontal">Lösungswort Senkrecht</label>
        </div>
      </div>
      <label htmlFor="inputSolWord">Frage für Lösungswort:</label>
      <input
        className={solwordStyles.inputSolWord}
        id="inputSolWord"
        type="text"
        name="solutionQuestion"
        placeholder="Frage Lösungswort..."
        value={solutionQuestion || ''}
        onBlur={(ev) => saveGameProperty('solution_question', ev.target.value)}
        onChange={(ev) => setSolutionQuestion(ev.target.value)}
      />
    </div>
  );
}

EditSolutionWord.propTypes = {
  game: PropTypes.object.isRequired,
  onSaveGame: PropTypes.func.isRequired,
};
