import React from 'react';
import PropTypes from 'prop-types';

import lastModStyles from './LastModifiedTag.module.css';

export default function LastModifiedTag({
  lastModified,
}) {
  return (
    <div className={lastModStyles.lastModified}>
      { lastModified
        ? (`Zuletzt gespeichert: ${lastModified}`)
        : ' '}
    </div>
  );
}

LastModifiedTag.defaultProps = {
  lastModified: undefined,
};

LastModifiedTag.propTypes = {
  lastModified: PropTypes.string,
};
