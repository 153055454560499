import React from 'react';
import PropTypes from 'prop-types';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import ActivityIndicator from '../../components/layout/ActivityIndicator/ActivityIndicator';
import NotFound from '../NotFound/NotFound';
import AppHead from '../../components/layout/AppHead/AppHead';
import GameHead from '../../components/game/GameHead/GameHead';
import ErrorMessage from '../../components/layout/MessageBox/ErrorMessage';
import EditQuestionBox from '../../components/questions/EditQuestionBox/EditQuestionBox';
import QuestionList from '../../components/questions/QuestionList/QuestionList';
import EditSideBar from '../../components/game/edit/EditSideBar/EditSideBar';
import Footer from '../../components/layout/Footer/Footer';
import Game from '../../components/game/Game/Game';
import LastModifiedTag from '../../components/game/LastModifiedTag/LastModifiedTag';

import GameStore from '../../models/GameStore';

import editGameStyle from './Edit.module.css';

class EditGamePage extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      hasError: false,
      game: {},
      displayGrid: [],
      gridValues: [],
      questions: [],
      activeX: 0,
      activeY: 0,
      isQuestionBoxShown: false,
      showErrorPage: false,
      showDoubled: false,
    };
  }

  componentDidMount() {
    const { match } = this.props;

    const cId = match.params.id;
    if (cId) {
      GameStore.getGame(cId, true)
        .then((gameResult) => {
          if (gameResult) {
            const [displayGrid, qList] = GameStore.getGridAndQuestions(gameResult.game, gameResult.grid_values, gameResult.questions);
            this.setState({
              isLoading: false,
              game: gameResult.game,
              displayGrid,
              gridValues: gameResult.grid_values,
              questions: qList,
            });
          } else {
            this.setErrorPage();
          }
        });
    }
  }

  setErrorPage() {
    this.setState({ showErrorPage: true });
  }

  setAlignment(isLeftAligned) {
    this.setState({ leftAlign: isLeftAligned });
  }

  setQuestionState(questions) {
    this.setState({ questions });
  }

  setQuestions(questions) {
    const { gridValues, game } = this.state;
    const [displayGrid, qList] = GameStore.getGridAndQuestions(game, gridValues, questions);
    this.setState({ displayGrid, questions: qList });
  }

  setGame(game) {
    const { gridValues, questions } = this.state;
    const [displayGrid, qList] = GameStore.getGridAndQuestions(game, gridValues, questions);
    this.setState({ game, displayGrid, questions: qList });
  }

  setActiveCellAndContent(xPos, yPos, activeDirectionToggle, selection, gridValues, callbackFct) {
    this.setState({
      activeX: xPos,
      activeY: yPos,
      isQuestionBoxShown: true,
      isHorizontalActive: activeDirectionToggle,
      selection,
      gridValues,
    }, callbackFct);
  }

  setError() {
    this.setState({ hasError: true }, () => { setTimeout(() => { this.setState({ hasError: false }); }, 3500); });
  }

  setActivePosition(xPos, yPos) {
    this.setState({
      activeX: xPos,
      activeY: yPos,
      isQuestionBoxShown: true,
    });
  }

  setActiveCell(xPos, yPos, activeDirectionToggle, selection, callbackFct) {
    this.setState({
      activeX: xPos,
      activeY: yPos,
      isQuestionBoxShown: true,
      isHorizontalActive: activeDirectionToggle,
      selection,
    }, callbackFct);
  }

  hideDoubled() {
    setTimeout(() => {
      this.setState({ showDoubled: false });
    }, 1000);
  }

  showDoubled() {
    this.setState({ showDoubled: true }, this.hideDoubled);
  }

  closeQuestionBox() {
    this.setState({ isQuestionBoxShown: false });
  }

  render() {
    const {
      showErrorPage,
      isLoading,
      game,
      hasError,
      activeX,
      activeY,
      displayGrid,
      gridValues,
      questions,
      isQuestionBoxShown,
      isHorizontalActive,
      showDoubled,
      leftAlign,
      selection,
    } = this.state;
    if (showErrorPage) {
      return <NotFound />;
    }
    if (isLoading) {
      return (
        <ActivityIndicator />
      );
    }

    const hasActiveCell = activeX !== null && activeY !== null;
    const currentCell = hasActiveCell ? game.grid[activeY][activeX] : null;
    return (
      <>
        <AppHead middleContent={<GameHead game={game} />} />
        <LastModifiedTag lastModified={game.last_modified} />
        {hasError && (<ErrorMessage />)}
        {hasActiveCell && (
          <EditQuestionBox
            gameId={game.id}
            questions={questions}
            activeX={activeX}
            activeY={activeY}
            isShown={isQuestionBoxShown}
            onQuestionStateChange={(q) => this.setQuestionState(q)}
            onQuestionChange={(q) => this.setQuestions(q)}
            onQuestionError={() => this.setError()}
            onClose={() => this.closeQuestionBox()}
          />
        )}
        <div className={editGameStyle.editGame}>
          <Game
            game={game}
            activeX={activeX}
            activeY={activeY}
            hasActiveCell={hasActiveCell}
            displayGrid={displayGrid}
            gridValues={gridValues}
            isHorizontalActive={isHorizontalActive}
            selection={selection}
            showDoubled={showDoubled}
            isEditable
            leftAlign={leftAlign}
            onError={() => this.setError()}
            onSetActiveCellAndContent={(x, y, a, s, v, c) => this.setActiveCellAndContent(x, y, a, s, v, c)}
            onPositionActivation={() => {}}
            onSetActiveCell={(x, y, a, s, c) => this.setActiveCell(x, y, a, s, c)}
          />
          <EditSideBar
            onGameChanged={(g) => this.setGame(g)}
            onShowDoubled={() => this.showDoubled()}
            onOpenToggle={(isOpen) => this.setAlignment(isOpen)}
            currentCell={currentCell}
            selection={selection}
            game={game}
            gridValues={gridValues}
            questions={questions}
          />
        </div>
        <QuestionList questions={questions} />
        <Footer />
      </>
    );
  }
}

export default withAuthenticationRequired(EditGamePage);

EditGamePage.defaultProps = {
  match: undefined,
};

EditGamePage.propTypes = {
  match: PropTypes.object,
};
