import React from 'react';
import AppHead from '../../components/layout/AppHead/AppHead';
import Footer from '../../components/layout/Footer/Footer';

export default function NotFound() {
  return (
    <div>
      <AppHead middleContent={<span>Auf Abwegen geraten</span>} />
      <h1>404</h1>
      <small>
           Die Seite wurde leider nicht gefunden :(
      </small>
      <Footer />
    </div>
  );
}
