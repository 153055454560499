/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { paddedNumber } from '../../../lib/utilities';
import solwordStyles from './SolutionWord.module.css';

export default class SolutionWord extends React.Component {
  setNextActiveInput(input) {
    this.nextActiveInput = input;
  }

  getCell(index, coord, allCoords, style) {
    const {
      onCellMouseEnter,
      onActivate,
      activeX,
      activeY,
    } = this.props;

    let inputRef = null;
    if (activeX === coord.x && activeY === coord.y) {
      inputRef = (input) => { this.setNextActiveInput(input); };
    }

    return (
      <input
        key={index}
        type="text"
        ref={inputRef}
        className={cn(solwordStyles.cell, solwordStyles.cellEditable, { [solwordStyles.active]: this.isActive(coord) })}
        style={style}
        value={coord.value}
        maxLength="1"
        autoFocus={activeX === coord.x && activeY === coord.y}
        onClick={() => onActivate(coord.x, coord.y)}
        onChange={(i) => this.saveAnswer(i, coord.x, coord.y, allCoords)}
        onMouseEnter={() => onCellMouseEnter(coord.x, coord.y)}
      />
    );
  }

  getSolutionWordCoords() {
    const { game, gridValues } = this.props;
    return game.grid.flatMap((row) => row.filter((c) => c.belongsToSolutionWord))
      .sort((c1, c2) => (game.solution_word_direction
        ? `${paddedNumber(c1.y)}-${paddedNumber(c1.x)}`.localeCompare(`${paddedNumber(c2.y)}-${paddedNumber(c2.x)}`)
        : `${paddedNumber(c1.x)}-${paddedNumber(c1.y)}`.localeCompare(`${paddedNumber(c2.x)}-${paddedNumber(c2.y)}`)))
      .map((c) => ({
        y: c.y,
        x: c.x,
        value: gridValues[c.y][c.x],
      }));
  }

  saveAnswer(i, x, y, allCoords) {
    const { onAnswerChange } = this.props;
    const index = allCoords.findIndex((c) => c.x === x && c.y === y);
    const nextCoord = allCoords.length - 1 > index ? allCoords[index + 1] : allCoords[index];
    onAnswerChange(x, y, nextCoord.x, nextCoord.y, i.target.value, () => this.nextActiveInput.focus());
  }

  isActive(coord) {
    const { activeX, activeY } = this.props;
    return activeX === coord.x && activeY === coord.y;
  }

  render() {
    const { game } = this.props;
    const solutionWordCoords = this.getSolutionWordCoords();
    const cellSize = Math.min(Math.floor(document.documentElement.clientWidth / solutionWordCoords.length), 75);
    const currentStyle = { width: cellSize, height: cellSize };
    const display = solutionWordCoords.map((coord, index) => this.getCell(index, coord, solutionWordCoords, currentStyle));
    return (
      <section className={solwordStyles.solutionWord}>
        <div className={solwordStyles.heading}>Lösungswort:</div>
        <div className={solwordStyles.question}>{game.solution_question}</div>
        <div className={solwordStyles.answer}>
          {display}
        </div>
      </section>
    );
  }
}

SolutionWord.defaultProps = {
  onAnswerChange: undefined,
  onActivate: undefined,
  onCellMouseEnter: undefined,
  gridValues: [],
  activeX: undefined,
  activeY: undefined,
};

SolutionWord.propTypes = {
  game: PropTypes.object.isRequired,
  gridValues: PropTypes.array,
  onAnswerChange: PropTypes.func,
  onActivate: PropTypes.func,
  onCellMouseEnter: PropTypes.func,
  activeX: PropTypes.number,
  activeY: PropTypes.number,
};
