/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import ZoomButton from '../../layout/ZoomButton/ZoomButton';
import HelpButton from '../../help/HelpButton/HelpButton';
import SolutionCheckButton from '../SolutionCheckButton/SolutionCheckButton';
import ZoomSize from '../../../lib/enums';

import gameHeadStyle from './GameHead.module.css';

export default function GameHead({
  game,
  gridValues,
  onWrongFieldValues,
  onHelpClicked,
  onToggleZoom,
  showZoom,
  onSolutionChecked,
  zoomSize,
}) {
  return (
    <div className={gameHeadStyle.gameHead}>
      <div className={cn(gameHeadStyle.iconNavigation, gameHeadStyle.left)}>
        {onHelpClicked && <HelpButton openHelpOverlay={onHelpClicked} />}
      </div>
      <div className={gameHeadStyle.title}>
        <span>Auf Abwegen geraten</span>
        <br />
        <span>Ausgabe vom { game.date_published }</span>
      </div>
      <div className={cn(gameHeadStyle.iconNavigation, gameHeadStyle.right)}>
        {onWrongFieldValues && onSolutionChecked
        && (
        <SolutionCheckButton
          showText={false}
          sticky={false}
          game={game}
          gridValues={gridValues}
          onSolutionError={onWrongFieldValues}
          onSolutionChecked={onSolutionChecked}
        />
        )}
        {showZoom && onToggleZoom && <ZoomButton zoomSize={zoomSize} onZoom={(z) => onToggleZoom(z)} />}
      </div>
    </div>
  );
}

GameHead.defaultProps = {
  onHelpClicked: undefined,
  onToggleZoom: undefined,
  onWrongFieldValues: undefined,
  onSolutionChecked: undefined,
  gridValues: undefined,
  zoomSize: ZoomSize.STANDARD,
  showZoom: true,
};

GameHead.propTypes = {
  game: PropTypes.object.isRequired,
  gridValues: PropTypes.array,
  onHelpClicked: PropTypes.func,
  onToggleZoom: PropTypes.func,
  onWrongFieldValues: PropTypes.func,
  onSolutionChecked: PropTypes.func,
  zoomSize: PropTypes.number,
  showZoom: PropTypes.bool,
};
