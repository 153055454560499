import React from 'react';

export default function GameListHead() {
  return (
    <div>
      <div>
        <span>Auf Abwegen geraten</span>
        <br />
        <span>Alle bisherigen Rätsel</span>
      </div>
    </div>
  );
}
