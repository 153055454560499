import React from 'react';
import PropTypes from 'prop-types';

import statsBarStyles from './StatsBar.module.css';

export default function StatsBar({ amount, totalSize }) {
  return (
    <span className={statsBarStyles.statsBar}>
      <span className={statsBarStyles.animate} style={{ width: `${(amount / totalSize) * 100}%` }}>&nbsp;</span>
      <span className={statsBarStyles.amount}>{amount}</span>
    </span>
  );
}

StatsBar.defaultProps = {
  amount: 0,
};

StatsBar.propTypes = {
  amount: PropTypes.number,
  totalSize: PropTypes.number.isRequired,
};
