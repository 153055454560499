/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import QuestionTag from '../QuestionTag/QuestionTag';
import qStyle from './ReadonlyQuestionBox.module.css';

export default class ReadonlyQuestionBox extends React.Component {
  constructor() {
    super();
    this.lastScrollPos = 0;

    window.onscroll = () => {
      const focused = document.activeElement;
      if (focused.classList.contains('cell')) {
        return;
      }

      const currentScrollPos = window.pageYOffset;
      const qBox = document.getElementById('q-Box');
      if (qBox) {
        if (currentScrollPos > this.lastScrollPos) {
          qBox.classList.add(qStyle.scrollDown);
          qBox.classList.remove(qStyle.scrollUp);
        } else {
          qBox.classList.remove(qStyle.scrollDown);
          qBox.classList.add(qStyle.scrollUp);
        }
      }
      this.lastScrollPos = currentScrollPos;
    };
  }

  render() {
    const {
      questions,
      activeQNrH,
      activeQNrV,
      isShown,
      isHorizontalQ,
    } = this.props;

    if (!isShown) {
      return null;
    }
    if ((!activeQNrH || activeQNrH < 0) && (!activeQNrV || activeQNrV < 0)) {
      return null;
    }

    // re-initialize
    const qBox = document.getElementById('q-Box');
    if (qBox) {
      qBox.classList.remove(qStyle.scrollUp);
      qBox.classList.remove(qStyle.scrollDown);
    }

    const questionH = questions.find((q) => q.qNr === activeQNrH && q.direction === true);
    const questionV = questions.find((q) => q.qNr === activeQNrV && q.direction === false);

    return (
      <div className={qStyle.qBox} id="q-Box">
        {activeQNrH
          ? (
            <div className={cn(qStyle.qBoxRowSolve, isHorizontalQ ? qStyle.active : '')}>
              <span className={qStyle.qBoxRowNr}>Nr {activeQNrH}: </span>{
              questionH ? <QuestionTag qNo={activeQNrH} direction question={questionH.question} /> : ''
              }
            </div>
          )
          : (<div><br /></div>)}
        {activeQNrV
          ? (
            <div className={cn(qStyle.qBoxRowSolve, !isHorizontalQ ? qStyle.active : '')}>
              <span className={qStyle.qBoxRowNr}>Nr {activeQNrV}: </span>{
              questionV ? <QuestionTag qNo={activeQNrV} direction={false} question={questionV.question} /> : ''
              }
            </div>
          )
          : (<div><br /></div>)}
      </div>
    );
  }
}

ReadonlyQuestionBox.defaultProps = {
  questions: [],
  activeQNrH: undefined,
  activeQNrV: undefined,
  isShown: true,
  isHorizontalQ: false,
};

ReadonlyQuestionBox.propTypes = {
  questions: PropTypes.array,
  activeQNrH: PropTypes.number,
  activeQNrV: PropTypes.number,
  isShown: PropTypes.bool,
  isHorizontalQ: PropTypes.bool,
};
