import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import CellContextMenu from '../CellContextMenu/CellContextMenu';
import PublishButton from '../PublishButton/PublishButton';
import EditSolutionWord from '../EditSolutionWord/EditSolutionWord';
import Stats from '../../../stats/Stats';

import GameStore from '../../../../models/GameStore';
import sidebarStyles from './EditSideBar.module.css';

export default class EditSideBar extends React.Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
  }

  setCellProperty(property) {
    const { selection, game } = this.props;
    selection.forEach((sel) => {
      this.setProperty(property.propName,
        property.propValue,
        sel.x,
        sel.y);

      if (property.relatedPropName) {
        this.setProperty(property.relatedPropName,
          property.propValue,
          sel.x + property.relatedOffsetX,
          sel.y + property.relatedOffsetY);
      }
    });

    this.saveGame(game);
  }

  setProperty(propName, propValue, x, y) {
    const { game } = this.props;
    if (x < 0 || y < 0 || game.grid.length <= y) {
      return;
    }
    const row = game.grid[y];
    if (row.length <= x) {
      return;
    }
    const cell = row[x];
    cell[propName] = propValue;
    game.grid[y][x] = cell;
  }

  publishGame(solWord) {
    const { game, questions, onGameChanged } = this.props;
    GameStore.publishGame(game.id, solWord, questions)
      .then((data) => {
        if (data) {
          game.date_published = data.date_published;
          onGameChanged(game);
        }
      });
  }

  saveGame(game) {
    const { onGameChanged } = this.props;
    GameStore.saveGame(game)
      .then((gameResult) => {
        onGameChanged(gameResult.game);
      });
  }

  toggleOpen() {
    const { isOpen } = this.state;
    const { onOpenToggle } = this.props;
    this.setState({ isOpen: !isOpen });
    onOpenToggle(!isOpen);
  }

  render() {
    const {
      game,
      gridValues,
      currentCell,
      questions,
      onGameChanged,
      onShowDoubled,
    } = this.props;
    const { isOpen } = this.state;
    return (
      <>
        <aside className={cn(sidebarStyles.editSidebar, { [sidebarStyles.open]: isOpen })}>
          <CellContextMenu
            activeCell={currentCell}
            onCellPropertyChange={(prop) => this.setCellProperty(prop)}
          />
          <button type="button" onClick={() => this.toggleOpen()} className={sidebarStyles.toggleSidebar}>{isOpen ? '>' : '<'}</button>
          <EditSolutionWord game={game} onSaveGame={(g) => this.saveGame(g)} onSetGameState={onGameChanged} />
          <PublishButton onClick={(s) => this.publishGame(s)} game={game} gridValues={gridValues} />
          <Stats currentCrosswordId={game.id} gridValues={gridValues} questions={questions} onShowDoubledClick={onShowDoubled} />
        </aside>
      </>
    );
  }
}

EditSideBar.defaultProps = {
  onGameChanged: () => {},
  onShowDoubled: () => {},
  onOpenToggle: () => {},
  selection: [],
  currentCell: null,
};

EditSideBar.propTypes = {
  selection: PropTypes.array,
  game: PropTypes.object.isRequired,
  gridValues: PropTypes.array.isRequired,
  questions: PropTypes.array.isRequired,
  onGameChanged: PropTypes.func,
  onShowDoubled: PropTypes.func,
  onOpenToggle: PropTypes.func,
  currentCell: PropTypes.object,
};
