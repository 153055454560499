import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';

import './App.css';
import GameList from './pages/List/List';
import EditGamePage from './pages/Detail/Edit';
import SolveGamePage from './pages/Detail/Solve';
import NotFound from './pages/NotFound/NotFound';

function App() {
  return (
    <main className="App">
      <Router>
        <Switch>
          <Route path="/games/" render={(props) => <GameList {...props} publishedOnly={false} />} />
          <Route path="/aag/edit/:id?" render={(props) => <EditGamePage {...props} />} />
          <Route path="/aag/solve/:id?" render={(props) => <SolveGamePage {...props} />} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </main>
  );
}

export default App;
