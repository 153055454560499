import React from 'react';
import PropTypes from 'prop-types';

import ctxMenuStyles from './CellContextMenu.module.css';
import icons from '../../../../img/GameIcons.svg';

const CellContextMenu = ({
  activeCell,
  onCellPropertyChange,
}) => {
  if (!activeCell) {
    return null;
  }

  return (
    <div className={ctxMenuStyles.contextMenu}>
      <div className={ctxMenuStyles.borders}>
        <svg
          className={`${ctxMenuStyles.icon} ${ctxMenuStyles.left} ${activeCell.borderLeft ? ctxMenuStyles.active : ''}`}
          onClick={() => onCellPropertyChange({
            relatedPropName: 'borderRight',
            relatedOffsetX: -1,
            relatedOffsetY: 0,
            propName: 'borderLeft',
            propValue: !activeCell.borderLeft,
          })}
        >
          <use xlinkHref={`${icons}#icon-border-vertical`} />
        </svg>
        <svg
          className={`${ctxMenuStyles.icon} ${ctxMenuStyles.top} ${activeCell.borderTop ? ctxMenuStyles.active : ''}`}
          onClick={() => onCellPropertyChange({
            relatedPropName: 'borderBottom',
            relatedOffsetX: 0,
            relatedOffsetY: -1,
            propName: 'borderTop',
            propValue: !activeCell.borderTop,
          })}
        >
          <use xlinkHref={`${icons}#icon-border-horizontal`} />
        </svg>
        <svg
          className={`${ctxMenuStyles.icon} ${ctxMenuStyles.right} ${activeCell.borderRight ? ctxMenuStyles.active : ''}`}
          onClick={() => onCellPropertyChange({
            relatedPropName: 'borderLeft',
            relatedOffsetX: 1,
            relatedOffsetY: 0,
            propName: 'borderRight',
            propValue: !activeCell.borderRight,
          })}
        >
          <use xlinkHref={`${icons}#icon-border-vertical`} />
        </svg>
        <svg
          className={`${ctxMenuStyles.icon} ${ctxMenuStyles.bottom} ${activeCell.borderBottom ? ctxMenuStyles.active : ''}`}
          onClick={() => onCellPropertyChange({
            relatedPropName: 'borderTop',
            relatedOffsetX: 0,
            relatedOffsetY: 1,
            propName: 'borderBottom',
            propValue: !activeCell.borderBottom,
          })}
        >
          <use xlinkHref={`${icons}#icon-border-horizontal`} />
        </svg>
      </div>
      <div>
        <label htmlFor="cbxDisabled">
          <input
            className={ctxMenuStyles.radio}
            type="checkbox"
            id="cbxDisabled"
            name="cbxDisabled"
            onChange={(e) => onCellPropertyChange({ propName: 'disabled', propValue: e.target.checked })}
            checked={activeCell.disabled || false}
          />
        Weisses Feld
        </label>
      </div>
      <div>
        <label htmlFor="cbxSolWord">
          <input
            className={ctxMenuStyles.radio}
            type="checkbox"
            id="cbxSolWord"
            name="cbxSolWord"
            onChange={(e) => onCellPropertyChange({ propName: 'belongsToSolutionWord', propValue: e.target.checked })}
            checked={activeCell.belongsToSolutionWord || false}
          />
        Teil des Lösungswortes
        </label>
      </div>
    </div>
  );
};

export default CellContextMenu;

CellContextMenu.defaultProps = {
  activeCell: undefined,
};

CellContextMenu.propTypes = {
  activeCell: PropTypes.object,
  onCellPropertyChange: PropTypes.func.isRequired,
};
