import React from 'react';
import PropTypes from 'prop-types';

import btnStyle from './PublishButton.module.css';

export default class PublishButton extends React.Component {
  isValid(successFct) {
    const { game, gridValues } = this.props;
    if (!game.solution_question || game.solution_question === '') {
      return false;
    }

    let solWord = '';

    if (game.solution_word_direction) {
      for (let y = 0; y < game.grid.length; y++) {
        for (let x = 0; x < game.grid[y].length; x++) {
          const cell = game.grid[y][x];
          if (!cell.disabled) {
            const cellContent = gridValues[y] ? gridValues[y][x] : undefined;
            if (!cellContent || cellContent === '') {
              return false;
            }
            solWord = cell.belongsToSolutionWord ? solWord + cellContent : solWord;
          }
        }
      }
    } else {
      if (game.grid.length <= 0) {
        return false;
      }

      for (let x = 0; x < game.grid[0].length; x++) {
        for (let y = 0; y < game.grid.length; y++) {
          const cell = game.grid[y][x];
          if (!cell.disabled) {
            const cellContent = gridValues[y] ? gridValues[y][x] : undefined;
            if (!cellContent || cellContent === '') {
              return false;
            }
            solWord = cell.belongsToSolutionWord ? solWord + cellContent : solWord;
          }
        }
      }
    }

    if (solWord.length < 2) {
      return false;
    }

    if (typeof successFct === 'function') {
      successFct(solWord);
    }

    return true;
  }

  publishGame() {
    const { onClick } = this.props;
    this.isValid((solWord) => onClick(solWord));
  }

  render() {
    const { game } = this.props;
    return (
      <div className={btnStyle.publishButton}>
        <button
          type="button"
          disabled={!this.isValid()}
          onClick={() => this.publishGame()}
        >Veröffentlichen
        </button>
        { game.date_published
          && (<div>Dieses Rätsel ist publiziert.</div>)}
      </div>
    );
  }
}

PublishButton.propTypes = {
  game: PropTypes.object.isRequired,
  gridValues: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
};
