import React from 'react';
import PropTypes from 'prop-types';

import ActivityIndicator from '../../components/layout/ActivityIndicator/ActivityIndicator';
import NotFound from '../NotFound/NotFound';
import AppHead from '../../components/layout/AppHead/AppHead';
import GameHead from '../../components/game/GameHead/GameHead';
import ErrorMessage from '../../components/layout/MessageBox/ErrorMessage';
import QuestionBox from '../../components/questions/ReadonlyQuestionBox/ReadonlyQuestionBox';
import QuestionList from '../../components/questions/QuestionList/QuestionList';
import Footer from '../../components/layout/Footer/Footer';
import Game from '../../components/game/Game/Game';
import LastModifiedTag from '../../components/game/LastModifiedTag/LastModifiedTag';
import HelpToolTip from '../../components/help/HelpToolTip/HelpToolTip';
import MessageBox from '../../components/layout/MessageBox/MessageBox';

import createBalloons from '../../lib/animation';
import GameStore from '../../models/GameStore';
import ZoomSize from '../../lib/enums';
import solveStyles from './Solve.module.css';
import { calculateCellSize } from '../../lib/utilities';

export default class SolveGamePage extends React.Component {
  static needsZoomBeShown(game) {
    const cellSize = calculateCellSize(game, ZoomSize.STANDARD);
    return cellSize * game.width > document.documentElement.clientWidth
      || cellSize * game.height > document.documentElement.clientHeight;
  }

  constructor() {
    super();
    this.state = {
      showErrorPage: false,
      showHelp: false,
      isLoading: true,
      hasError: false,
      game: {},
      questions: [],
      gridValues: [],
      activeX: 0,
      activeY: 0,
      isQuestionBoxShown: false,
      isHorizontalActive: true,
      zoomSize: ZoomSize.STANDARD,
    };
  }

  componentDidMount() {
    const { match } = this.props;

    const cId = match.params.id;
    if (cId) {
      GameStore.getGame(cId, false)
        .then((gameResult) => {
          if (gameResult) {
            const [displayGrid, qList] = GameStore.getGridAndQuestions(gameResult.game, gameResult.grid_values, gameResult.questions);
            this.setState({
              isLoading: false,
              game: gameResult.game,
              displayGrid,
              gridValues: gameResult.grid_values,
              questions: qList,
              isZoomShown: SolveGamePage.needsZoomBeShown(gameResult.game),
            });
          } else {
            this.setErrorPage();
          }
        });
    }
  }

  setSolutionChecked(nrOfWrongLetters, finished) {
    this.setState({ nrOfWrongLetters, finished }, () => {
      if (nrOfWrongLetters <= 0) {
        createBalloons('animation-container', 7);
      }
      setTimeout(() => {
        this.setState({ nrOfWrongLetters: 0, finished: false });
      }, 3500);
    });
  }

  setHelp(isShown) {
    this.setState({ showHelp: isShown });
  }

  setErrorPage() {
    this.setState({ showErrorPage: true });
  }

  setError() {
    this.setState({ hasError: true }, () => { setTimeout(() => { this.setState({ hasError: false }); }, 3500); });
  }

  setActivePosition(xPos, yPos) {
    this.setState({
      activeX: xPos,
      activeY: yPos,
      isQuestionBoxShown: true,
    });
  }

  setActiveCell(xPos, yPos, activeDirectionToggle, selection, callbackFct) {
    this.setState({
      activeX: xPos,
      activeY: yPos,
      isQuestionBoxShown: true,
      isHorizontalActive: activeDirectionToggle,
      selection,
    }, callbackFct);
  }

  setActiveCellAndGameContent(xPos, yPos, activeDirectionToggle, selection, gridValues, callbackFct) {
    this.setState({
      activeX: xPos,
      activeY: yPos,
      isQuestionBoxShown: true,
      isHorizontalActive: activeDirectionToggle,
      selection,
      gridValues,
    }, callbackFct);
  }

  toggleZoom(newSize) {
    this.setState({ zoomSize: newSize });
  }

  render() {
    const {
      showErrorPage,
      showHelp,
      isLoading,
      game,
      hasError,
      activeX,
      activeY,
      displayGrid,
      gridValues,
      questions,
      isQuestionBoxShown,
      isHorizontalActive,
      zoomSize,
      selection,
      finished,
      nrOfWrongLetters,
      isZoomShown,
    } = this.state;

    if (showErrorPage) {
      return <NotFound />;
    }
    if (isLoading) {
      return (
        <ActivityIndicator />
      );
    }
    const isSmallScreen = window.matchMedia('(max-width: 600px)').matches;
    const hasActiveCell = activeX !== null && activeY !== null;

    return (
      <>
        {finished && (<MessageBox type="success"><>Gelöst!</></MessageBox>) }
        {nrOfWrongLetters > 0
          && (
            <MessageBox type="error">
              <>
                Du bist irgendwo auf Abwege geraten beim Lösen: <span className={solveStyles.nrWrong}>{nrOfWrongLetters}</span>&nbsp;
                Felder stimmen noch nicht. Check Deine Antworten nochmals!
              </>
            </MessageBox>
          )}
        <div id="animation-container" />
        {showHelp && (<HelpToolTip onClose={() => this.setHelp(false)} />)}
        <AppHead middleContent={(
          <GameHead
            game={game}
            gridValues={gridValues}
            zoomSize={zoomSize}
            showZoom={isZoomShown}
            onToggleZoom={(z) => this.toggleZoom(z)}
            onHelpClicked={() => this.setHelp(true)}
            onWrongFieldValues={() => this.setError()}
            onSolutionChecked={(n, f) => this.setSolutionChecked(n, f)}
          />
        )}
        />
        <LastModifiedTag lastModified={game.last_modified} />
        {hasError && (<ErrorMessage />)}
        {hasActiveCell && (
          <QuestionBox
            activeQNrH={displayGrid[activeY][activeX].qH}
            activeQNrV={displayGrid[activeY][activeX].qV}
            questions={questions}
            isHorizontalQ={isHorizontalActive}
            isShown={isQuestionBoxShown}
          />
        )}
        <Game
          game={game}
          activeX={activeX}
          activeY={activeY}
          displayGrid={displayGrid}
          gridValues={gridValues}
          isHorizontalActive={isHorizontalActive}
          hasActiveCell={hasActiveCell}
          isEditable={false}
          leftAlign={false}
          zoomSize={zoomSize}
          selection={selection}
          isSmallScreen={isSmallScreen}
          onError={() => this.setError()}
          onSetActiveCellAndContent={(x, y, a, s, v, c) => this.setActiveCellAndGameContent(x, y, a, s, v, c)}
          onPositionActivation={(x, y) => this.setActivePosition(x, y)}
          onSetActiveCell={(x, y, a, s, c) => this.setActiveCell(x, y, a, s, c)}
          onSolutionError={() => this.setError()}
          onSolutionChecked={(w, f) => this.setSolutionChecked(w, f)}
        />
        <QuestionList questions={questions} />
        <Footer />
      </>
    );
  }
}

SolveGamePage.defaultProps = {
  match: undefined,
};

SolveGamePage.propTypes = {
  match: PropTypes.object,
};
