import React from 'react';
import PropTypes from 'prop-types';

const SizeSelector = ({
  title,
  name,
  value,
  onChange,
}) => {
  const id = `select${name}`;
  return (
    <div>
      <label htmlFor={id}>{title}</label>
      <select name={name} id={id} value={value} onChange={onChange}>
        <option value="">Bitte wählen</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
        <option value="8">8</option>
        <option value="9">9</option>
        <option value="10">10</option>
        <option value="11">11</option>
        <option value="12">12</option>
        <option value="13">13</option>
        <option value="14">14</option>
        <option value="15">15</option>
        <option value="16">16</option>
        <option value="17">17</option>
        <option value="18">18</option>
        <option value="19">19</option>
        <option value="20">20</option>
      </select>
    </div>
  );
};

export default SizeSelector;

SizeSelector.defaultProps = {
  value: '',
};

SizeSelector.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
