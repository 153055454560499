import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '../../layout/IconButton/IconButton';
import icons from '../../../img/GameIcons.svg';
import toolTipStyle from './HelpToolTip.module.css';

const HelpToolTip = ({ onClose }) => (
  <div className={toolTipStyle.tooltip}>
    <div className={toolTipStyle.topBar}>
      <div className={toolTipStyle.title}>So funktioniert&apos;s</div>
      <IconButton buttonText="schliessen" onClick={onClose} iconName="icon-close" buttonStyleClass={toolTipStyle.close} />
    </div>
    <div className={toolTipStyle.helpText}>
      <div>
        <div className={toolTipStyle.title}>Eingabe</div>
        <p>Wenn du in ein Feld klickst, beginnt dort deine Eingabe - in waagrechter Schreibrichtung.
          Jeder weitere Klick in dasselbe Feld ändert die Schreibrichtung.
          <br /><br />Mit den Pfeiltasten kannst du den Cursor in ein anderes Feld bewegen. Mit der Delete-Taste löschst du Felder in Schreibrichtung. Die Backspace-Taste löscht gegen die Schreibrichtung.
          <br /><br />Das Lösungswort kann auch direkt unter dem Rätselgitter eingegeben werden.
        </p>
      </div>
      <div>
        <div className={toolTipStyle.title}>Fragen</div>
        <p>Wenn du mit der Maus ein Rätselfeld berührst, erscheinen oben im grünen Balken die zugehörigen Hinweise.
          Die Frage für die aktuelle Schreibrichtung ist jeweils fett gedruckt.
        </p>
      </div>
      <div>
        <div className={toolTipStyle.title}>Lösung</div>
        <p>Wenn du das Rätsel fertig hast, kannst du auf das Signet
          <span className={toolTipStyle.icon}>
            <svg>
              <use xlinkHref={`${icons}#icon-check`} />
            </svg>
          </span> oben im grünen Balken klicken. Dann findest du heraus, ob du alles richtig gelöst hast.
        </p>
      </div>
      <div>
        <div className={toolTipStyle.title}>Ansicht</div>
        <p>Mit der Lupe
          <span className={toolTipStyle.icon}>
            <svg>
              <use xlinkHref={`${icons}#icon-zoom`} />
            </svg>
          </span> kannst du zwischen Gesamtansicht und Detailansicht des Rätselgitters wechseln.
        </p>
      </div>
    </div>
  </div>
);

export default HelpToolTip;

HelpToolTip.propTypes = {
  onClose: PropTypes.func.isRequired,
};
