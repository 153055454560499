import { doQuery } from './GeneralQuery';

const QuestionStore = {
  removeQuestion: (crosswordId, questionToRemove) => {
    const key = `${questionToRemove.x}-${questionToRemove.y}-${questionToRemove.direction}`;
    return doQuery('DELETE', `crosswords/${crosswordId}/questions/${key}`);
  },
  saveQuestion: (crosswordId, question) => {
    const isNew = !question || !question.date_created;
    const key = isNew ? '' : `${question.x}-${question.y}-${question.direction}`;
    return doQuery(isNew ? 'POST' : 'PUT', `crosswords/${crosswordId}/questions/${key}`, question);
  },
  getQuestionHistory: (crosswordId) => doQuery('GET', `answers/${crosswordId}`),
};

export default QuestionStore;
