import React from 'react';
import PropTypes from 'prop-types';

import appHeadStyles from './AppHead.module.css';

export default function AppHead({ middleContent }) {
  return (
    <header className={appHeadStyles.appHeader}>
      {middleContent}
    </header>
  );
}

AppHead.defaultProps = {
  middleContent: undefined,
};

AppHead.propTypes = {
  middleContent: PropTypes.object,
};
