import ZoomSize from './enums';

export function partition(inputArray, condition) {
  return inputArray.reduce((result, element) => {
    result[condition(element) ? 0 : 1].push(element);
    return result;
  }, [[], []]);
}

export function clone(items) {
  return items.map((item) => (Array.isArray(item) ? clone(item) : { ...item }));
}

export function paddedNumber(inputNr) { return inputNr.toString().padStart(2, '0'); }

export function calculateCellSize(game, zoomSize) {
  const MAX_WIDTH = 70;
  const MIN_WIDTH = 32;
  switch (zoomSize) {
    case ZoomSize.SMALL: {
      const smallWidth = Math.min(document.documentElement.clientWidth, document.documentElement.clientHeight);
      return Math.min(Math.floor((smallWidth - 180) / Math.max(game.width, game.height)), MAX_WIDTH) - 2;
    }
    case ZoomSize.STANDARD:
    default: {
      const standardWidth = document.documentElement.clientWidth;
      return Math.min(Math.floor(standardWidth / game.width), MAX_WIDTH) - 2;
    }
  }
}
