import React from 'react';
import indicatorStyles from './ActivityIndicator.module.css';
import icons from '../../../img/GameIcons.svg';

export default function ActivityIndicator() {
  return (
    <div className={indicatorStyles.loader}>
      <svg className={indicatorStyles.icon} viewBox="0 0 35 35">
        <use xlinkHref={`${icons}#spinner`} />
      </svg>
    </div>
  );
}
