/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';

import qTagStyles from './QuestionTag.module.css';

const QuestionTag = ({ qNo, direction, question }) => {
  const imgText = `Frage ${qNo} ${direction ? 'waagrecht' : 'senkrecht'}`;
  const questionInclImageTag = question.replace(/pic::([^\s]*\.(?:png|jpg|svg))/g, (_, imgName) => {
    const imgSrc = `/img/${imgName}`;
    return `<img src="${imgSrc}" alt="${imgText}" title="${imgText}" class="${qTagStyles.questionImg}" />`;
  });

  return <span className={qTagStyles.qText} dangerouslySetInnerHTML={{ __html: questionInclImageTag }} />;
};

export default QuestionTag;


QuestionTag.defaultProps = {
  qNo: undefined,
};

QuestionTag.propTypes = {
  qNo: PropTypes.number,
  direction: PropTypes.bool.isRequired,
  question: PropTypes.string.isRequired,
};
