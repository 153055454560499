/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '../../layout/IconButton/IconButton';
import { paddedNumber } from '../../../lib/utilities';
import QuestionStore from '../../../models/QuestionStore';

import qStyle from './EditQuestionBox.module.css';

export default class EditQuestionBox extends React.Component {
  constructor() {
    super();

    this.lastScrollPos = 0;
    window.onscroll = () => {
      const currentScrollPos = window.pageYOffset;
      const qBox = document.getElementById('q-Box');
      if (qBox) {
        if (currentScrollPos > this.lastScrollPos) {
          qBox.classList.add(qStyle.scrollDown);
          qBox.classList.remove(qStyle.scrollUp);
        } else {
          qBox.classList.remove(qStyle.scrollDown);
          qBox.classList.add(qStyle.scrollUp);
        }
      }
      this.lastScrollPos = currentScrollPos;
    };
  }

  saveQuestion(value, isHorizontal, stateOnly) {
    const {
      gameId,
      questions,
      activeX,
      activeY,
      onQuestionStateChange,
      onQuestionChange,
      onQuestionError,
    } = this.props;

    let word = questions.find((q) => q.x === activeX && q.y === activeY && q.direction === isHorizontal);
    if (!stateOnly && (!value || value === '')) {
      if (word) {
        this.removeQuestion(gameId, word);
      }
      return;
    }

    if (word) {
      word.question = value;
    } else {
      word = {
        crossword_id: gameId,
        question: value,
        x: activeX,
        y: activeY,
        direction: isHorizontal,
      };
      questions.push(word);
      questions.sort((a, b) => `${paddedNumber(a.y)}-${paddedNumber(a.x)}`.localeCompare(`${paddedNumber(b.y)}-${paddedNumber(b.x)}`));
    }

    if (stateOnly) {
      onQuestionStateChange(questions);
      return;
    }

    QuestionStore.saveQuestion(gameId, word)
      .then((result) => {
        if (!result) {
          onQuestionError();
        } else {
          onQuestionChange(result);
        }
      }, () => { onQuestionError(); });
  }

  removeQuestion(cwId, existingQuestion) {
    const { onQuestionChange } = this.props;
    QuestionStore.removeQuestion(cwId, existingQuestion)
      .then((result) => {
        if (result) {
          onQuestionChange(result);
        }
      }, () => { this.setError(); });
  }

  render() {
    const {
      questions,
      activeX,
      activeY,
      isShown,
      onClose,
    } = this.props;

    if (!isShown) {
      return null;
    }

    // re-initialize
    const qBox = document.getElementById('q-Box');
    if (qBox) {
      qBox.classList.remove(qStyle.scrollUp);
      qBox.classList.remove(qStyle.scrollDown);
    }

    const questionH = questions.find((q) => q.x === activeX && q.y === activeY && q.direction === true);
    const questionV = questions.find((q) => q.x === activeX && q.y === activeY && q.direction === false);

    return (
      <div className={qStyle.qBox} id="q-Box">
        <div className={qStyle.qBoxRow}>
          <span>Nr: {questionH ? questionH.qNr : null} </span>
          <label htmlFor="questionBoxH">Frage horizontal: </label>
          <input
            id="questionBoxH"
            type="text"
            name="questionH"
            placeholder="Frage horizontal"
            value={questionH ? questionH.question : ''}
            onBlur={(ev) => this.saveQuestion(ev.target.value, true)}
            onChange={(ev) => this.saveQuestion(ev.target.value, true, true)}
          />
        </div>
        <div className={qStyle.qBoxRow}>
          <span>Nr: {questionV ? questionV.qNr : null} </span>
          <label htmlFor="questionBoxV">Frage vertikal: </label>
          <input
            id="questionBoxV"
            type="text"
            name="questionV"
            placeholder="Frage vertikal"
            value={(questionV ? questionV.question : '')}
            onBlur={(ev) => this.saveQuestion(ev.target.value, false)}
            onChange={(ev) => this.saveQuestion(ev.target.value, false, true)}
          />
        </div>
        <IconButton buttonStyleClass={qStyle.closeIcon} buttonText="schliessen" onClick={onClose} iconName="icon-close" />
      </div>
    );
  }
}

EditQuestionBox.defaultProps = {
  questions: [],
  activeX: undefined,
  activeY: undefined,
  isShown: true,
  onQuestionStateChange: () => {},
  onQuestionChange: () => {},
  onQuestionError: () => {},
  onClose: () => {},
};

EditQuestionBox.propTypes = {
  gameId: PropTypes.number.isRequired,
  questions: PropTypes.array,
  activeX: PropTypes.number,
  activeY: PropTypes.number,
  isShown: PropTypes.bool,
  onQuestionStateChange: PropTypes.func,
  onQuestionChange: PropTypes.func,
  onQuestionError: PropTypes.func,
  onClose: PropTypes.func,
};
