import React from 'react';

import footerStyle from './Footer.module.css';

export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer className={footerStyle.footer}>
     Copyright &copy;{currentYear} | Auf Abwegen geraten
    </footer>
  );
}
