/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import ZoomSize from '../../../lib/enums';
import { calculateCellSize } from '../../../lib/utilities';
import GameCell from '../GameCell/GameCell';
import gameBoardStyle from './GameBoard.module.css';

export default class GameBoard extends React.Component {
  constructor() {
    super();
    this.state = {
    };
  }

  componentDidMount() {
    const { isEditable, onGameMouseLeave } = this.props;

    if (this.nextActiveInput) {
      setTimeout(() => this.nextActiveInput.focus(), 50);
    }
    if (!isEditable) {
      document.getElementById('gameBoard').addEventListener('mouseleave', ((ev) => onGameMouseLeave(ev)));
    }
  }

  componentWillUnmount() {
    const { onGameMouseLeave } = this.props;
    document.getElementById('gameBoard').removeEventListener('mouseleave', ((ev) => onGameMouseLeave(ev)));
  }

  setNextActiveInput(input) {
    this.nextActiveInput = input;
  }

  createRow(row, rowIndex, cellSize, cellValues) {
    const {
      useKeyboard,
      onCellMouseEnter,
      onCellMouseLeave,
      onCellKeydown,
      isEditable,
      onCellClick,
      activeX,
      activeY,
      isHorizontalActive,
      hasActiveCell,
      selection,
      showDoubled,
      displayGrid,
      onGameContentChange,
    } = this.props;

    return row.map((cell, cellIndex) => {
      let inputRef = null;
      // active: selected or mouse-overed
      const isActiveCell = selection.findIndex((s) => s.x === cellIndex && s.y === rowIndex) >= 0
        || (activeX === cellIndex && activeY === rowIndex);
      if (cellIndex === activeX && rowIndex === activeY) {
        inputRef = (input) => { this.setNextActiveInput(input); };
      }

      const cellValue = cellValues[cellIndex];
      const showBoth = showDoubled && cell.qH > 0 && cell.qV > 0;
      const isMouseOveredH = showBoth || (hasActiveCell && cell.qH && displayGrid[activeY][activeX].qH === cell.qH);
      const isMouseOveredV = showBoth || (hasActiveCell && cell.qV && displayGrid[activeY][activeX].qV === cell.qV);

      return (
        <GameCell
          readOnly={useKeyboard}
          inputRef={inputRef}
          key={`${cellIndex}-${rowIndex}`}
          x={cellIndex}
          y={rowIndex}
          qNr={cell.qNr}
          cellSize={cellSize}
          cellValue={cellValue}
          qH={cell.qH}
          qV={cell.qV}
          showHorizontalQ={isHorizontalActive}
          disabled={cell.disabled}
          borderLeft={cell.borderLeft}
          borderRight={cell.borderRight}
          borderTop={cell.borderTop}
          borderBottom={cell.borderBottom}
          mouseOveredH={isMouseOveredH}
          mouseOveredV={isMouseOveredV}
          belongsToSolutionWord={cell.belongsToSolutionWord}
          onMouseEnter={onCellMouseEnter}
          onMouseLeave={onCellMouseLeave}
          onCellKeydown={(isShiftkeyDown, code, v) => onCellKeydown(isShiftkeyDown, code, v, () => setTimeout(() => this.nextActiveInput.focus(), 0))}
          onChange={(x, y, v) => onGameContentChange(v, x, y, () => setTimeout(() => this.nextActiveInput.focus(), 0))}
          onClick={onCellClick}
          isEditable={isEditable}
          isActiveCell={isActiveCell}
        />
      );
    });
  }

  render() {
    const {
      displayGrid,
      gridValues,
      leftAlign,
      zoomSize,
      game,
    } = this.props;
    const cellSize = calculateCellSize(game, zoomSize);
    const listItems = displayGrid.map((row, rowIndex) => (
      <div className={gameBoardStyle.row} key={rowIndex}>
        {this.createRow(row, rowIndex, cellSize, gridValues[rowIndex])}
      </div>
    ));

    return (
      <section
        id="gameBoard"
        className={cn(gameBoardStyle.gameBoard, { [gameBoardStyle.leftAlign]: leftAlign })}
        ref={(input) => { this.board = input; }}
      >
        {listItems}
      </section>
    );
  }
}

GameBoard.defaultProps = {
  activeX: undefined,
  activeY: undefined,
  isHorizontalActive: true,
  hasActiveCell: false,
  showDoubled: false,
  leftAlign: false,
  useKeyboard: false,
  selection: [],
  zoomSize: ZoomSize.STANDARD,
  onGameMouseLeave: () => {},
  onCellMouseEnter: () => {},
  onCellMouseLeave: () => {},
  onCellKeydown: () => {},
  onCellClick: () => {},
};

GameBoard.propTypes = {
  game: PropTypes.object.isRequired,
  selection: PropTypes.array,
  displayGrid: PropTypes.array.isRequired,
  gridValues: PropTypes.array.isRequired,
  isEditable: PropTypes.bool.isRequired,
  activeX: PropTypes.number,
  activeY: PropTypes.number,
  hasActiveCell: PropTypes.bool,
  zoomSize: PropTypes.number,
  showDoubled: PropTypes.bool,
  leftAlign: PropTypes.bool,
  isHorizontalActive: PropTypes.bool,
  useKeyboard: PropTypes.bool,
  onGameMouseLeave: PropTypes.func,
  onCellMouseEnter: PropTypes.func,
  onCellMouseLeave: PropTypes.func,
  onCellKeydown: PropTypes.func,
  onGameContentChange: PropTypes.func.isRequired,
  onCellClick: PropTypes.func,
};
