import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import msgBoxStyles from './MessageBox.module.css';

// eslint-disable-next-line arrow-body-style
const MessageBox = ({ type, children }) => {
  const getStyleByType = () => {
    switch (type) {
      case 'success':
        return msgBoxStyles.success;
      case 'error':
        return msgBoxStyles.error;
      default:
        return '';
    }
  };

  return (
    <div className={cn(msgBoxStyles.msgBox, getStyleByType())}>
      {children}
    </div>
  );
};

export default MessageBox;

MessageBox.defaultProps = {
  type: 'success',
};

MessageBox.propTypes = {
  type: PropTypes.string,
  children: PropTypes.object.isRequired,
};
