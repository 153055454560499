function random(num) {
  return Math.floor(Math.random() * num);
}

function getRandomStyles() {
  const r = random(255);
  const g = random(255);
  const b = random(255);
  const mt = random(200);
  const ml = random(100) + 100;
  const dur = random(5) + 5;
  return `
  background-color: rgba(${r},${g},${b},0.7);
  color: rgba(${r},${g},${b},0.7); 
  box-shadow: inset -7px -3px 10px rgba(${r - 10},${g - 10},${b - 10},0.7);
  margin: ${mt}px 0 0 ${ml}px;
  animation: float ${dur}s ease-in infinite
  `;
}

export default function createBalloons(containerId, num) {
  const container = document.getElementById(containerId);
  for (let i = num; i > 0; i--) {
    const balloon = document.createElement('span');
    balloon.className = 'balloon';
    balloon.style.cssText = getRandomStyles();
    container.append(balloon);
    container.classList.add('show');
  }
}
