import React from 'react';
import PropTypes from 'prop-types';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import GameBoard from '../GameBoard/GameBoard';
import SolutionWord from '../SolutionWord/SolutionWord';
import SolutionCheckButton from '../SolutionCheckButton/SolutionCheckButton';
import FixedBottom from '../../layout/FixedBottom/FixedBottom';

import GameStore from '../../../models/GameStore';
import ZoomSize from '../../../lib/enums';

import gameStyle from './Game.module.css';

export default class Game extends React.Component {
  constructor() {
    super();
    this.state = {
      pressedKeyCode: null,
    };
    this.onSaveGameValues = new Subject();
    this.rootEl = document.getElementById('root');
  }

  componentDidMount() {
    const { onError, isEditable } = this.props;
    this.saveSubscription = this.onSaveGameValues
      .pipe(debounceTime(600))
      .subscribe(() => {
        this.saveGameContentOnServer();
      }, () => onError());
    if (!isEditable) {
      this.rootEl.addEventListener('click', ((ev) => this.removeActiveCell(ev, true)));
    }
  }

  componentWillUnmount() {
    if (this.saveSubscription) {
      this.saveSubscription.unsubscribe();
    }
    this.rootEl.removeEventListener('click', ((ev) => this.removeActiveCell(ev, true)));
  }

  getNextFocus(xPos, yPos, offsetX, offsetY) {
    const { displayGrid } = this.props;
    const nextYPos = yPos + offsetY;
    const nextXPos = xPos + offsetX;

    if (nextXPos < 0 // left end
      || nextYPos < 0 // upper end
      || nextYPos >= displayGrid.length // bottom end
      || nextXPos >= displayGrid[nextYPos].length // right end
      || displayGrid[nextYPos][nextXPos].disabled) { // disabled cell
      return { nextY: yPos, nextX: xPos };
    }

    return { nextY: nextYPos, nextX: nextXPos };
  }

  getNextActiveCell(xPos, yPos, doToggle, isShiftkeyPressed) {
    const {
      activeX,
      activeY,
      isHorizontalActive,
      selection,
    } = this.props;
    let activeDirectionToggle = isHorizontalActive;
    if (activeX === xPos && activeY === yPos) {
      activeDirectionToggle = doToggle ? !isHorizontalActive : true;
    }

    if (!isShiftkeyPressed) {
      selection.length = 0;
    }

    if (xPos !== null && yPos !== null) {
      selection.push({ x: xPos, y: yPos });
    }

    return { activeDirectionToggle, selection };
  }

  setActiveCellAndContent(xPos, yPos, gridValues, callbackFct = () => {}) {
    const {
      onSetActiveCellAndContent,
    } = this.props;

    const nextCell = this.getNextActiveCell(xPos, yPos, false, false);

    this.setState({ pressedKeyCode: null }, () => {
      onSetActiveCellAndContent(xPos, yPos, nextCell.activeDirectionToggle, nextCell.selection, gridValues, callbackFct);
    });
  }

  setActiveCell(xPos, yPos, isShiftkeyPressed, callbackFct = () => {}, doToggle = true) {
    const {
      onSetActiveCell,
    } = this.props;

    const nextCell = this.getNextActiveCell(xPos, yPos, doToggle, isShiftkeyPressed);

    this.setState({ pressedKeyCode: null }, () => {
      onSetActiveCell(xPos, yPos, nextCell.activeDirectionToggle, nextCell.selection, callbackFct);
    });
  }

  setNextFocus(isShiftkeyPressed, keyCode, cellValue, callbackFct) {
    const { activeX, activeY, isHorizontalActive } = this.props;
    const doFocusWork = ({ nextX, nextY }) => {
      this.setActiveCell(nextX, nextY, isShiftkeyPressed, callbackFct);
    };

    switch (keyCode) {
      case 39: // right
        doFocusWork(this.getNextFocus(activeX, activeY, 1, 0));
        break;
      case 37: // left
        doFocusWork(this.getNextFocus(activeX, activeY, -1, 0));
        break;
      case 40: // down
        doFocusWork(this.getNextFocus(activeX, activeY, 0, 1));
        break;
      case 38: // up
        doFocusWork(this.getNextFocus(activeX, activeY, 0, -1));
        break;
      case 8: // backspace
        if (cellValue) {
          this.rememberKeyCode(keyCode);
        } else {
          this.rememberKeyCode(keyCode,
            () => doFocusWork(this.getNextFocus(activeX, activeY, isHorizontalActive ? -1 : 0, !isHorizontalActive ? -1 : 0)));
        }
        break;
      default:
        break;
    }
  }

  handleKeyboardInput(v) {
    const { activeX, activeY } = this.props;
    this.saveGameContent(v, activeX, activeY);
  }

  rememberKeyCode(keyCode, callbackFct) {
    this.setState({ pressedKeyCode: keyCode }, callbackFct);
  }

  removeActiveCell(ev, clearSelection) {
    if (ev.target.classList.contains('keyboard-input')) {
      return;
    }

    const { selection } = this.props;
    if (clearSelection && selection.length > 0) {
      selection.length = 0;
    }
    if (selection.length === 0
      && !ev.target.classList.contains('cell')) {
      this.setActiveCell(null, null);
    }
  }

  saveGameContent(character, xPos, yPos, nextFocusX, nextFocusY, callbackFct) {
    const { isHorizontalActive, gridValues, selection } = this.props;
    const {
      pressedKeyCode,
    } = this.state;

    let val = character.replace(/[a-z]/, (match) => match.toUpperCase());
    val = val.replace(/[YJ]/, 'I');
    const isValid = /^[A-Za]{0,1}$/.test(val);
    if (!isValid) {
      return;
    }

    let offset = null;
    switch (pressedKeyCode) {
      case 8:
        offset = -1;
        break;
      default:
        offset = 1;
        break;
    }

    selection.forEach((el) => {
      gridValues[el.y][el.x] = val;
    });

    if (!nextFocusX || !nextFocusY) {
      if ((!xPos || !yPos) && selection.length > 0) {
        const lastSelected = selection.at(-1);
        // eslint-disable-next-line no-param-reassign
        xPos = lastSelected.x;
        // eslint-disable-next-line no-param-reassign
        yPos = lastSelected.y;
      }
      const { nextX, nextY } = this.getNextFocus(xPos, yPos, isHorizontalActive ? offset : 0, isHorizontalActive ? 0 : offset);
      this.setActiveCellAndContent(nextX, nextY, gridValues, callbackFct);
    } else {
      this.setActiveCellAndContent(nextFocusX, nextFocusY, gridValues, callbackFct);
    }

    this.onSaveGameValues.next(gridValues);
  }

  saveGameContentOnServer() {
    const {
      onError,
      game,
      isEditable,
      gridValues,
    } = this.props;
    GameStore.saveGameContent(game, gridValues, isEditable)
      .then((gameResult) => {
        if (!gameResult) {
          onError();
        }
      });
  }

  render() {
    const {
      game,
      displayGrid,
      activeX,
      activeY,
      isHorizontalActive,
      hasActiveCell,
      onPositionActivation,
      onSolutionError,
      onSolutionChecked,
      zoomSize,
      isEditable,
      gridValues,
      selection,
      showDoubled,
      leftAlign,
      isSmallScreen,
    } = this.props;

    const bottomComponent = isSmallScreen
      ? (
        <FixedBottom
          onKeyboardInput={(v) => this.handleKeyboardInput(v)}
          game={game}
          gridValues={gridValues}
          onSolutionError={() => onSolutionError()}
          onSolutionChecked={(n, f) => onSolutionChecked(n, f)}
        />
      )
      : (
        <SolutionCheckButton
          showText
          sticky
          game={game}
          gridValues={gridValues}
          onSolutionError={() => onSolutionError()}
          onSolutionChecked={(n, f) => onSolutionChecked(n, f)}
        />
      );

    return (
      <div className={gameStyle.game}>
        <GameBoard
          game={game}
          displayGrid={displayGrid}
          gridValues={gridValues}
          isEditable={isEditable}
          activeX={activeX}
          activeY={activeY}
          hasActiveCell={hasActiveCell}
          useKeyboard={isSmallScreen}
          zoomSize={zoomSize}
          selection={selection}
          showDoubled={showDoubled}
          leftAlign={leftAlign}
          isHorizontalActive={isHorizontalActive}
          onGameMouseLeave={(ev) => this.removeActiveCell(ev, false)}
          onCellMouseEnter={(x, y) => onPositionActivation(x, y)}
          onGameContentChange={(content, x, y, callback) => this.saveGameContent(content, x, y, null, null, callback)}
          onCellClick={(doToggle, x, y, qH, qV, iSp) => this.setActiveCell(x, y, iSp, () => {}, doToggle)}
          onCellKeydown={(iSp, c, v, callbackFct) => this.setNextFocus(iSp, c, v, callbackFct)}
        />
        <SolutionWord
          gridValues={gridValues}
          activeX={activeX}
          activeY={activeY}
          game={game}
          onAnswerChange={(x, y, nextX, nextY, value, callbackFct) => this.saveGameContent(value, x, y, nextX, nextY, callbackFct)}
          onActivate={(x, y) => this.setActiveCell(x, y, false, () => {}, false)}
          onCellMouseEnter={(x, y) => onPositionActivation(x, y)}
        />
        {bottomComponent}
      </div>
    );
  }
}

Game.defaultProps = {
  zoomSize: ZoomSize.STANDARD,
  activeX: undefined,
  activeY: undefined,
  isHorizontalActive: true,
  selection: [],
  showDoubled: false,
  leftAlign: false,
  hasActiveCell: false,
  isSmallScreen: false,
  onSolutionChecked: () => {},
  onSolutionError: () => {},
};

Game.propTypes = {
  game: PropTypes.object.isRequired,
  displayGrid: PropTypes.array.isRequired,
  gridValues: PropTypes.array.isRequired,
  activeX: PropTypes.number,
  activeY: PropTypes.number,
  isHorizontalActive: PropTypes.bool,
  hasActiveCell: PropTypes.bool,
  isSmallScreen: PropTypes.bool,
  isEditable: PropTypes.bool.isRequired,
  showDoubled: PropTypes.bool,
  leftAlign: PropTypes.bool,
  zoomSize: PropTypes.number,
  selection: PropTypes.array,
  onError: PropTypes.func.isRequired,
  onSetActiveCellAndContent: PropTypes.func.isRequired,
  onPositionActivation: PropTypes.func.isRequired,
  onSetActiveCell: PropTypes.func.isRequired,
  onSolutionError: PropTypes.func,
  onSolutionChecked: PropTypes.func,
};
