/* eslint-disable no-param-reassign */
import { clone } from '../lib/utilities';
import { doQuery } from './GeneralQuery';

const GameStore = {
  getGame(cwId, isEditable) {
    return doQuery('GET', `crosswords/${cwId}/${isEditable ? '?editable=true' : ''}`);
  },
  checkSolution(gameId, gridValues) {
    return doQuery('POST', `crosswords/${gameId}/solution/check`, gridValues);
  },
  publishGame(gameId, solutionWord, questions) {
    return doQuery('POST', `crosswords/${gameId}/publish/`, { solutionWord, questions });
  },
  saveGame(game) {
    const request = new Request(`${process.env.REACT_APP_BASE_URL}/crosswords/${game.id}`,
      { method: 'PUT', body: JSON.stringify(game), credentials: 'include' });
    request.headers.set('Content-Type', 'application/json');

    return fetch(request)
      .then((response) => {
        if (!response.ok) {
          return null;
        }
        game.last_modified = new Date().toLocaleString();
        return { game };
      }, (error) => {
        console.log(error);
        return null;
      });
  },
  saveGameContent(game, gridValues, isEditable) {
    const request = new Request(`${process.env.REACT_APP_BASE_URL}/crosswords/${game.id}/${isEditable ? 'grid' : 'solution'}`,
      { method: 'PUT', body: JSON.stringify(gridValues), credentials: 'include' });
    request.headers.set('Content-Type', 'application/json');

    return fetch(request)
      .then((resp) => (resp.ok ? resp.json() : null))
      .then((gridResult) => {
        if (gridResult) {
          game.last_modified = new Date().toLocaleString();
          return { game, gridValues: gridResult };
        }
        return null;
      });
  },
  getGridAndQuestions(game, gridValues, questions) {
    const gridWithQ = clone(game.grid);

    const qKeys = new Map();
    let counter = 1;
    questions.forEach((q) => {
      const cell = gridWithQ[q.y][q.x];
      if (!cell.qNr) {
        cell.qNr = counter;
        counter++;
      }

      if (q.direction) {
        cell.borderLeft = true;
        if (q.x > 0) {
          gridWithQ[q.y][q.x - 1].borderRight = true;
        }
      } else {
        cell.borderTop = true;
        if (q.y > 0) {
          gridWithQ[q.y - 1][q.x].borderBottom = true;
        }
      }

      q.qNr = cell.qNr;
      q.key = `${q.x}-${q.y}-${q.direction}`;

      qKeys.set(q.key, q);
    });

    // collect solution horizontally
    let currentQ = null;
    let currentWord = '';
    let qLength = 0;
    let isNextNoQCellH = true;
    for (let y = 0; y < game.height; y++) {
      currentQ = null;
      currentWord = '';
      qLength = 0;
      for (let x = 0; x < game.width; x++) {
        const cell = gridWithQ[y][x];

        const q = qKeys.get(`${x}-${y}-true`);
        if (q || cell.disabled || cell.borderLeft) {
          if (currentQ) {
            currentQ.solution = currentWord;
            currentQ.length = qLength;
          }
          currentQ = q;
          currentWord = gridValues[y][x];
          qLength = 1;
        } else if (currentQ) {
          currentWord += gridValues[y][x];
          qLength++;
        }

        if (currentQ) {
          if (cell.qNr || (!isNextNoQCellH && !cell.borderLeft && !cell.disabled)) {
            cell.qH = currentQ.qNr;
          }
        }

        isNextNoQCellH = cell.borderRight || cell.disabled;
      }

      if (currentQ) {
        currentQ.solution = currentWord;
        currentQ.length = qLength;
      }

      isNextNoQCellH = true; // reset on next row
    }

    // collect vertically
    currentQ = null;
    let isNextNoQCellV = true;
    for (let x = 0; x < game.width; x++) {
      currentQ = null;
      currentWord = '';
      qLength = 0;
      for (let y = 0; y < game.height; y++) {
        const cell = gridWithQ[y][x];

        const q = qKeys.get(`${x}-${y}-false`);
        if (q || cell.disabled || cell.borderTop) {
          if (currentQ) {
            currentQ.solution = currentWord;
            currentQ.length = qLength;
          }
          currentQ = q;
          currentWord = gridValues[y][x];
          qLength = 1;
        } else if (currentQ) {
          currentWord += gridValues[y][x];
          qLength++;
        }

        if (currentQ) {
          if (cell.qNr || (!isNextNoQCellV && !cell.borderTop && !cell.disabled)) {
            cell.qV = currentQ.qNr;
          }
        }

        isNextNoQCellV = cell.borderBottom || cell.disabled;
      }

      if (currentQ) {
        currentQ.solution = currentWord;
        currentQ.length = qLength;
      }

      isNextNoQCellV = true; // reset for next column
    }

    return [gridWithQ, questions];
  },
};
export default GameStore;
