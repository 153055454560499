import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '../../layout/IconButton/IconButton';
import btnStyle from './HelpButton.module.css';

const HelpButton = ({ openHelpOverlay }) => (
  <IconButton
    buttonStyleClass={btnStyle.help}
    buttonText="Wie funktioniert es?"
    iconName="icon-help"
    onClick={openHelpOverlay}
  />
);

export default HelpButton;

HelpButton.propTypes = {
  openHelpOverlay: PropTypes.func.isRequired,
};
