/* eslint-disable react/sort-comp */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';

import createGameStyle from './CreateGame.module.css';
import SizeSelector from '../../../layout/SizeSelector/SizeSelector';

export default class CreateGame extends React.Component {
  constructor() {
    super();
    this.state = {
      newGame: {
        grid: [],
        grid_values: [],
      },
    };
  }

  static fillGrid(newGame) {
    for (let i = 0; i < newGame.height; i++) {
      const gridCols = [];
      const cellCols = [];
      for (let j = 0; j < newGame.width; j++) {
        gridCols.push({ x: j, y: i, disabled: false });
        cellCols.push('');
      }
      newGame.grid.push(gridCols);
      newGame.grid_values.push(cellCols);
    }
  }

  setFieldValue(name, value) {
    const { newGame } = this.state;
    newGame[name] = value;
    this.setState({ newGame });
  }

  handleChange(name, value) {
    const { newGame } = this.state;
    newGame[name] = value;
    this.setState({ newGame });
    if (this.validateGame()) {
      CreateGame.fillGrid(newGame);
      this.saveGame(newGame);
    }
  }

  validateGame() {
    const { newGame } = this.state;
    return newGame.name && newGame.width > 0 && newGame.height > 0;
  }

  saveGame(game) {
    const { onCreate } = this.props;
    const request = new Request(`${process.env.REACT_APP_BASE_URL}/crosswords`,
      { method: 'POST', body: JSON.stringify(game) });
    request.headers.set('Content-Type', 'application/json');

    fetch(request)
      .then((response) => {
        if (response.ok) {
          onCreate();
        }
      }, (error) => { console.log(error); });
  }

  render() {
    const { newGame } = this.state;

    return (
      <div>
        <div className="title-text">Neues Rätsel erstellen</div>
        <form className={createGameStyle.createGame}>
          {newGame.id && (<div>Nummer: {newGame.id}</div>)}
          <div>
            <label htmlFor="inputName">Name:</label>
            <input
              id="inputName"
              type="text"
              name="name"
              placeholder="Name Kreuzworträtsel"
              value={newGame.name || ''}
              onBlur={(ev) => this.handleChange(ev.target.name, ev.target.value)}
              onChange={(ev) => this.setFieldValue(ev.target.name, ev.target.value)}
            />
          </div>
          <SizeSelector
            title="Anzahl Zeilen:"
            name="height"
            value={newGame.height || '11'}
            onChange={(event) => this.handleChange(event.target.name, event.target.value)}
          />
          <SizeSelector
            title="Anzahl Spalten:"
            name="width"
            value={newGame.width || '18'}
            onChange={(event) => this.handleChange(event.target.name, event.target.value)}
          />
        </form>
      </div>
    );
  }
}

CreateGame.propTypes = {
  onCreate: PropTypes.func.isRequired,
};
