import React from 'react';
import PropTypes from 'prop-types';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import ListItem from '../../components/list/ListItem/ListItem';
import ActivityIndicator from '../../components/layout/ActivityIndicator/ActivityIndicator';
import CreateGame from '../../components/game/create/CreateGame/CreateGame';
import AppHead from '../../components/layout/AppHead/AppHead';
import Footer from '../../components/layout/Footer/Footer';
import GameListHead from '../../components/list/GameListHead/GameListHead';
import ErrorMessageBox from '../../components/layout/MessageBox/ErrorMessage';

import gameListStyle from './List.module.css';

class GameList extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      games: [],
      error: false,
    };
  }

  componentDidMount() {
    this.loadGames();
  }

  loadGames() {
    const { publishedOnly } = this.props;
    fetch(`${process.env.REACT_APP_BASE_URL}/crosswords/${publishedOnly ? '?published=true' : ''}`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return null;
      })
      .then((gameList) => {
        this.setState({ error: gameList === null, isLoading: false, games: gameList });
      });
  }

  deleteGame(gameId) {
    fetch(`${process.env.REACT_APP_BASE_URL}/crosswords/${gameId}`, {
      method: 'DELETE',
      credentials: 'include',
    })
      .then((response) => {
        if (response.ok) {
          this.loadGames();
        } else {
          this.setState({ error: true });
        }
      });
  }

  render() {
    const { isLoading, games, error } = this.state;
    const { publishedOnly } = this.props;
    if (isLoading) {
      return (<div className="GameList"><ActivityIndicator /></div>);
    }

    const rows = games.map((game) => <ListItem key={game.id} game={game} enableEdit={!publishedOnly} onDeleteClick={(id) => this.deleteGame(id)} />);

    return (
      <>
        <AppHead middleContent={<GameListHead />} />
        <div className={gameListStyle.games}>
          {error && <ErrorMessageBox />}
          <div>
            <ul>{rows}</ul>
          </div>
          {!publishedOnly && <CreateGame onCreate={() => this.loadGames()} />}
        </div>
        <Footer />
      </>
    );
  }
}

export default withAuthenticationRequired(GameList);

GameList.defaultProps = {
  publishedOnly: true,
};

GameList.propTypes = {
  publishedOnly: PropTypes.bool,
};
