import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import IconButton from '../../layout/IconButton/IconButton';
import GameStore from '../../../models/GameStore';

import btnStyle from './SolutionCheckButton.module.css';

export default class SolutionCheckButton extends React.Component {
  isValid() {
    const { game, gridValues } = this.props;

    for (let j = 0; j < game.grid.length; j++) {
      for (let i = 0; i < game.grid[j].length; i++) {
        const cell = game.grid[j][i];
        if (!cell.disabled) {
          const cellContent = gridValues[j] ? gridValues[j][i] : undefined;
          if (!cellContent || cellContent === '') {
            return false;
          }
        }
      }
    }

    return true;
  }

  checkSolution() {
    const {
      game,
      gridValues,
      onSolutionError,
      onSolutionChecked,
    } = this.props;
    if (this.isValid()) {
      GameStore.checkSolution(game.id, gridValues)
        .then((response) => {
          if (response) {
            onSolutionChecked(response.nrOfWrongLetters, response.nrOfWrongLetters === 0);
          } else {
            onSolutionError();
          }
        });
    }
  }

  render() {
    if (!this.isValid()) {
      return null;
    }

    const { showText, sticky } = this.props;

    return (
      <>
        <IconButton
          buttonText="Rätsel prüfen"
          showText={showText}
          buttonStyleClass={cn(btnStyle.checkMe, { [btnStyle.sticky]: sticky })}
          iconName="icon-check"
          onClick={() => this.checkSolution()}
        />
      </>
    );
  }
}

SolutionCheckButton.propTypes = {
  game: PropTypes.object.isRequired,
  gridValues: PropTypes.array.isRequired,
  onSolutionError: PropTypes.func.isRequired,
  onSolutionChecked: PropTypes.func.isRequired,
  showText: PropTypes.bool.isRequired,
  sticky: PropTypes.bool.isRequired,
};
