import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '../IconButton/IconButton';
import ZoomSize from '../../../lib/enums';
import btnStyle from './ZoomButton.module.css';

const ZoomButton = ({
  zoomSize,
  onZoom,
}) => {
  let iconName = '';
  let nextSize = -1;
  let btnText = 'Ganzes Rätselgitter anzeigen';
  switch (zoomSize) {
    case ZoomSize.SMALL:
      nextSize = ZoomSize.STANDARD;
      iconName = 'bigger';
      btnText = 'Rätselgitter vergrössern';
      break;
    case ZoomSize.STANDARD:
      nextSize = ZoomSize.SMALL;
      iconName = 'smaller';
      break;
    default:
      nextSize = ZoomSize.SMALL;
      iconName = 'smaller';
  }

  return (
    <IconButton
      buttonText={btnText}
      onClick={() => onZoom(nextSize)}
      buttonStyleClass={btnStyle.zoomButton}
      iconName={`icon-zoom-${iconName}`}
    />
  );
};

export default ZoomButton;

ZoomButton.defaultProps = {
  zoomSize: ZoomSize.STANDARD,
};

ZoomButton.propTypes = {
  zoomSize: PropTypes.number,
  onZoom: PropTypes.func.isRequired,
};
