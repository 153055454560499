/* eslint-disable import/prefer-default-export */
export function doQuery(method, relativeUrl, data = null) {
  const reqOptions = { method, credentials: 'include' };
  if (data) {
    reqOptions.body = JSON.stringify(data);
  }
  const request = new Request(`${process.env.REACT_APP_BASE_URL}/${relativeUrl}`, reqOptions);
  request.headers.set('Content-Type', 'application/json');

  return fetch(request)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      return null;
    }, (error) => {
      console.log(error);
      return null;
    });
}
